<template>
    <page-loading :show="!info" />
    <div class="page" v-if="info">
        <img class="banner" :src="info.banner" />
        <div class="shop-info">
            <strong class="shop-name">{{info.name}}<template v-if="info.chain_name">（{{info.chain_name}}）</template></strong><br/>
            <span v-if="info.date_data_text[0]" class="shop-item shop-hours">营业时间：{{info.date_data_text[0]}}</span><br/>
            <div class="shop-address-box">
                <span class="shop-item shop-address">{{info.address}}</span>
                <button class="post-video-btn" @click="gotoVideo">去打卡</button>
            </div>
        </div>
    </div>

</template>

<script>
// import mixin from '@/assets/js/mixin'
import api from '@/api/haiping'

const res = {"code":200,"msg":"success","payload":{"shop_id":"202304211005218802","merchant_id":"b202304211003255017","created":"2023-04-21 10:05:21","create_date":"2023-04-21","create_time":1682042721,"business_license_no":null,"business_license_pic":null,"business_license_name":null,"shop_name":"\u9759\u95e8\u5e971","long_term":"0","date_limitation":null,"longitude":"116.412292","latitude":"39.927318","province_code":"110000","city_code":"110100","district_code":"110101","detail_address":"\u94b1\u7cae\u5357\u5df7180\u53f7","province":"\u5317\u4eac\u5e02","city":"\u5317\u4eac\u5e02\u5e02\u8f96\u533a\u4e1c\u57ce\u533a","district":"\u4e1c\u57ce\u533a","contact_tel":"15666666666","contact_name":"\u963f\u5f3a","zfb_shop_id":"","zfb_is_shop":"1","shop_info_status":"","reason":"","order_id":null,"sound_type":"1","sound_id":"","msg_token":"","is_orgin_price":"0","is_order_sync":"1","is_coupon":"1","min_peisong_sal":100,"peisong_json":"[{\"distance\":\"0-2\u516c\u91cc\",\"sal\":500},{\"distance\":\"2-3\u516c\u91cc\",\"sal\":800},{\"distance\":\"3-5\u516c\u91cc\",\"sal\":1000},{\"distance\":\"5\u516c\u91cc\u4ee5\u4e0a\",\"sal\":1500}]","peison_type":"1","peisong_sal":0,"peison_distance":5000,"candao_id":"0","auto_order_receiving":"0","date_data_json":"[]","date_data_text":[],"zf_default_sal":"","out_door_images_url":null,"cert_image_url":null,"cert_image":null,"out_door_images":null,"menu_address":"","is_open_shop":"1","is_goods_check":"1","distribution_model":"3","status":1,"poi_id":"","poi_url":"","shop_salesnum":0,"shop_avg":"0","name":"\u9759\u95e8\u5e971","task_type":2,"task_status":1,"cz_status":0,"run_status":2,"business_date":"","uniq_topic":"","video_title":[],"banner":"https:\/\/lingchao-seek-hpyq.oss-cn-shanghai.aliyuncs.com\/hp\/20230524\/62da18494a43a12b4ef3ddb0199ac6a0.jpg","uid":"","spu_id":"","welfare_text":"","has_welfare":0,"coupon":[],"special_coupon":[],"activity":[],"my_coupon":[],"address":"\u94b1\u7cae\u5357\u5df7180\u53f7"}}


export default {
    // mixins: [mixin],
    data() {
        return {
            active_id: '',
            shop_id: '',
            info: null,
        }
    },
    created() {
        console.log('index-created', this.queries)
        
        this.getInfo();
    },
    mounted() {
        console.log('index-mounted')
    },
    methods: {
        // 获取任务详情/app/hpyq_task
        getInfo() {
            
            this.info = res.payload;
            this.info.address = this.info.city + this.info.address;

            document.title = this.info.name;

            if (!this.shop_id) {
                this.shop_id = this.info.id;
			}
        },

        // 获取标题和话题
        getTitleAndTopics() {
            const videoTitle = this.info.video_title || [];
            if (videoTitle.length === 0) return {
                title: '',
                topics: []
            }

            // 多个标题随机获取一个
            let randomIndex = parseInt(Math.random() * videoTitle.length);
            // let strArr = videoTitle[randomIndex].split('#');
            let randomTitle = videoTitle[randomIndex];
            console.log('randomTitle', randomTitle)
            // let topics = randomTitle.match(/(?<=#)(.+?(?=#|\s|$))/g);
            let topicsArr = randomTitle.match(/(#.+?(?=#|\s|$))/g) || [];
            let topics = topicsArr.map(item => item.replace('#',''))
            console.log('topics', topics)
            let title = randomTitle.replace(/(#.+?(?=#|\s|$))/g, '');
            console.log('title', title)
            // let title = videoTitle[randomIndex];
            // let topics = title.split('#').slice(1);

            return {
                title,
                topics
            };
        },
     
        // 跳转发布视频页
        async gotoVideo() {
            this.$toast('活动已结束');
            return false
            
            if (this.info.task_status === 2) {
                this.$toast('活动已结束');
                return false;
            }

            if (this._lock) return false;
            this._lock = true;

            const res = await this.getVideo()

            if (!res) {
                this._lock = false;
                return false;
            }

            if (res) {
                this._lock = false;
                location.href = `haiping/video.html?active_id=${this.active_id}&shop_id=${this.shop_id}`
            }
        },

        // 获取视频/dy/hpyq_task_video
        getVideo() {
            sessionStorage.removeItem('videoInfo');

            return api.getHpyqTaskVideo({
                hpyq_active_id: this.active_id
            }).then(res => {
                if (res.code === 200) {
                    const { title, topics} = this.getTitleAndTopics();
                    const uniq_topic = (this.info.uniq_topic || '').replace('#', '');
                    const poi_id = this.info.poi_id
                    
                    const videoInfo = {
                        ...res.payload,
                        uniq_topic,
                        topics,
                        title,
                        poi_id,
                        uid: this.info.uid
                    }
                    
                    const isMov = ((videoInfo.url || '').split('.').pop() || '').toUpperCase() === 'MOV'
                    const isOldThumb = videoInfo.thumb === 'https://lingchao-hpyq.oss-cn-shanghai.aliyuncs.com/hp/20220615/aefcd86a1e625025ecb0c3e6bb83f46b.jpeg'
                    if (isMov || isOldThumb) {
                        videoInfo.thumb = 'https://lingchao-hpyq.oss-cn-shanghai.aliyuncs.com/hp/20220712/88138ba977a80968508751401865e6bb.png'
                    }

                    sessionStorage.setItem('videoInfo', JSON.stringify(videoInfo))
                    return true
                }

                this.$toast(res.msg)

                return false
            }).catch(err => {
                if (err.errNo !== 21103) { // 接口超时在底层已拦截会弹框，这里就不需要弹框了
                    this.$toast(err.msg)
                }
                
                return false
            })
        }
        
    }
}
</script>

<style lang="scss" scoped>
    .page {
        background-color: #fff;
    }
    .banner {
        width: 100%;
        height: 275px;
    }
    .shop {
        &-info {
            position: relative;
            width: 355px;
            min-height: 108px;
            margin: -16px auto 0;
            padding: 20px 14px;
            font-size: 0;
            box-sizing: border-box;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 0px rgba(91, 91, 91, 0.15);
            border-radius: 8px;
            background: #fff;
        }
        &-name {
            display: inline-block;
            color: #333;
            font-size: 18px;
            font-weight: 600;
            line-height: 18px;
            margin-bottom: 10px;
        }
        &-item {
            display: inline-block;
            margin-bottom: 8px;
            font-size: 12px;
            color: #666;
            line-height: 16px;
            padding: 0 4px;
            background: #F5F5F5;
        }
        &-address-box {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .post-video-btn {
                margin-top: -14px;
            }
        }
        &-address {
            margin-right: 31px;
            margin-bottom: 0;
        }
    }

    .post-video {
        margin: 15px auto 0;
        padding: 13px 14px 16px 15px;
        width: 355px;
        min-height: 76px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(90deg, #FFFFFF 0%, #FFF7F4 100%);
        box-shadow: 0px 0px 10px 0px rgba(91, 91, 91, 0.15);
        border-radius: 8px;
        &-info {

        }
        &-title {
            display: block;
            font-size: 16px;
            font-weight: 500;
            padding-left: 30px;
            margin-bottom: 8px;
            background: url(~@/assets/images/haiping/gift.png) left no-repeat;
            background-size: 24px;
        }
        &-desc {
            color: #666;
            font-size: 12px;
            width: 200px;
            margin-left: 30px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        &-btn {
            flex: none;
            width: 72px;
            height: 30px;
            color: #fff;
            font-size: 14px;
            text-align: center;
            line-height: 30px;
            background: linear-gradient(270deg, #FA3391 0%, #FD4848 100%);
            border-radius: 15px;
            border: 0;
            @include click-active;
        }
    }

    .coupon {
        &-list {
            width: 100%;
            margin-top: 15px;
            padding: 0 15px;
        }
        &-header {
            // padding: 10px 0;
            line-height: 35px;
            font-size: 16px;
            font-weight: 500;
        }
        &-view-all {
            float: right;
            padding-right: 10px;
            color: #666;
            font-size: 14px;
            background: url(~@/assets/images/haiping/icon_arrow.png) right no-repeat;
            background-size: 5px;
            @include click-active;
        }
        &-empty {
            padding: 150px 0 50px;
            color: #999;
            font-size: 14px;
            text-align: center;
            background: url(~@/assets/images/haiping/empty.png) center 42px no-repeat;
            background-size: 125px;
        }
        &-item {
            margin-top: 10px;
        }
    }

    .btn-bottom-wrap {
        height: 100px;
    }
    .bottom-btn-box {
        position: fixed;
        bottom: 0;
        width: 375px;
        height: 88px;
        padding-top: 8px;
        background: #FFFFFF;
        box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.07);        
    }
    .btn-check {
        margin: 0 auto;
        width: 320px;
        height: 44px;
        color: #fff;
        font-size: 16px;
        text-align: center;
        line-height: 44px;
        background: linear-gradient(90deg, #FF986C 0%, #FF611E 100%);
        border-radius: 22px; 
        @include click-active;      
    }
    .post-video-btn-bottom {
        margin-top: 30px;
    }
</style>
