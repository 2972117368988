import { createApp } from 'vue'
import App from './App.vue'
import useComponents from '@/assets/js/vant-ui'
// import commonInit from '@/assets/js/common'


const app = createApp(App)
useComponents(app)


app.mount('#app')